import React from "react"; 
import { GatsbyImage, getImage  } from "gatsby-plugin-image";
export default function CategoryButton({ category = null, pullWord }) {
  const image = getImage(category.category.image);
  // console.log(category)
  return (
    <button
      onClick={() => pullWord(category.category.slug)}
      className="block text-left group hover:bg-blue-700 p-2 md:p-4 border-b flex w-full"
    >
    {category.category.image && category.category.image.extension !== "svg" && image && (
      <div className="lg:mr-4 mr-2">
        <GatsbyImage className={'cat-image group-hover:bg-white'} image={image} alt={category.category.name} />
      </div>
    )}
    {category.category.image && category.category.image.extension === "svg" && (
      <div className="lg:mr-4 mr-2 ">
        <img  className={'cat-image group-hover:bg-white'} src={category.category.image.url} alt={category.category.name} />
      </div>
    )}
      <div className={"flex-grow "} >
        <p className="font-bold text-lg lg:text-4xl mb-1 text-black text-blue-900 group-hover:text-white w-full">
          {category.category.name}
        </p>
        {category.category.description ? (
          <p className="text-sm lg:text-base mb-0 group-hover:text-white text-blue-900 w-full ">
            {category.category.description}
          </p>
        ) : null}
      </div>
    </button>
  );
}
