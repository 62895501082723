import React, { useState, useEffect } from "react";
import GameTemplate from "./game-template.js";
import { ToastProvider } from "react-toast-notifications";
import { Link } from "gatsby";
import Layout from "./layout";
import Seo from "./seo";
import CategoryButton from "./category-button.js";
import HeaderAd from "./ads/header-ad.js";
import GameService from "../services/game.service.js";
import { AuthContext } from "../context/auth-context"; 

const WordCategoryWrapper = ({ data }) => {
  const { user,  setActiveCat } = React.useContext(AuthContext);
  const [word, setWord] = useState(null);
  // const [gameOn, setGameOn] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [activeGame, setActiveGame] = useState(null);
  const [gameDetails, setGameDetails] = useState(null);
   
  useEffect(() => {
    // pullWord();
  }, []);

  const pullWord = async (slug) => {
    setLoading(true); 
    let newWord;
    await setActiveCat(slug);
    await setWord(null);
    try {
      if (user !== null) {
        newWord = await GameService.returnWordAuth(slug);
      } else {
        newWord = await GameService.returnWord(slug);
      }
      if (newWord.foundWord && newWord.foundWord.length > 0) {
        setWord(newWord.foundWord[0]);
        // console.log("here", newWord);
        setGameDetails(newWord.game);
      }
      setLoading(false);
    } catch (error) {
      console.log("There was an error");
      await setWord(null);
      await setLoading(false);
      alert(
        "It looks like you have run out of words in this category, nice work! Check back shortly as we are always adding new words to our database"
      );
    }
  };

  return (
    <ToastProvider placement="bottom-center">
      <Layout isHomePage>
        <Seo title={`${data.category.name} - Word Category`} />
        <div>
          <HeaderAd />
        </div>
        {word && (
          <GameTemplate
            word={word}
            pullWord={pullWord}
            gameDetails={gameDetails}
          />
        )}
        {loading === true && (
          <div
            className={
              "fixed inset-0 z-40 flex items-center justify-center bg-blue-500"
            }
          >
            <p className={`text-2xl text-white`}>Loading Word</p>
          </div>
        )}
        <div className={"w-full block py-10 text-center"}>
          <h1 className={"text-4xl font-bold mb-10 mt-4"}>
            {`${data.category.name}`}{" "}
          </h1>

          {data.children.categorys && (
            <>
              <h4 className={"text-2xl font-bold mb-10 mt-4"}>
                Choose a Subcategory of {data.category.name}
              </h4>
              <div className="font-sans flex   justify-center bg-blue-darker w-full py-8 container mx-auto">
                <div className="overflow-hidden bg-white rounded max-w-full w-full shadow-lg  leading-normal ">
                  <CategoryButton
                    category={data}
                    pullWord={pullWord}
                  />

                  {data.children.categorys.map((cat, i) => {
                    return (
                      <CategoryButton
                        key={`${cat.category.slug}cat`}
                        category={cat}
                        pullWord={pullWord}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
          <p className={"text-center mt-6 "}>
            <Link to={"/"}>Back to Category List</Link>
          </p>
        </div>
      </Layout>
    </ToastProvider>
  );
};

export default WordCategoryWrapper;
