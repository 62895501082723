import React from "react";
import { AuthContext } from "../context/auth-context";

export default function DisplayEndGame({ oldScore, score }) {
  const { profile, user } = React.useContext(AuthContext);
  return profile !== null && user !== null ? (
    <div
      className={
        "container mx-auto font-sans py-4 px-4 text-xs text-gray-700 py-6 text-center lg:text-left"
      }
    >
      <p className={"text-3xl font-bold text-center "}>
        score: <span className={"text-green-500"}>{score}</span>
      </p>
      <p className={"text-3xl font-bold text-center "}>
        from: <span className={"text-red-500"}>{oldScore}</span>
      </p>
      <p className={"text-6xl font-bold text-center mb-6"}>
        to: <span className={"text-blue-900"}>{oldScore + score}</span>
      </p>
      <p className={"mb-4 text-center"}>
        You have have {profile.wins} wins and {profile.loses} failed attempts
        out of {profile.totalGames} games. Your average score is{" "}
        {Math.round(profile.average)} per game. Total Score so far is{" "}
        {profile.totalScore}.
      </p>
    </div>
  ) : null;
}
