import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Horay from "./Horay";
import GameService from "../../services/game.service";
import { AuthContext } from "../../context/auth-context";
import DisplayEndGame from "../display-endgame";

function EndGameScreen({ 
  status = "started",
  activeWord = null,
  word, 
  finalScore = 0,
  pullWord,
  gameDetails, 
  timer
}) {
  const { activeCat, user, profile, setProfile } = React.useContext(
    AuthContext
  );
  const [loading, setLoading] = useState(false);
  const [oldScore, setOldScore] = useState(null)

  useEffect(() => {
    // console.log("The game has ended", gameDetails, user);

    const updateGame = async () => {
      if (user !== null) {
        const result = await GameService.endGameAuth({
          _id: gameDetails._id,
          payload: {
            score: finalScore,
            status: status,
            seconds: timer,
          },
        });
        console.log("result",result)
        setProfile(result.summary)
      } else {
        GameService.endGame({
          _id: gameDetails._id,
          payload: {
            score: finalScore,
            status: status,
            seconds: timer,
          },
        });
      }
    };

    if(profile !== null){
      const old = profile.totalScore
      console.log("Old score", profile, old)
      setOldScore(old)
    }


    updateGame();

  }, []);

  return (
    <div className="EndGame overflow-y-scroll fixed top-0 right-0 bottom-0 left-0 inset-0 bg-white lg:flex lg:items-center z-40">
      {loading === true && (
        <div
          className={
            "fixed inset-0 z-50 flex items-center justify-center bg-blue-500"
          }
        >
          <p className={`text-2xl text-white`}>Loading Word</p>
        </div>
      )}
      {status === "won" ? <Horay /> : null}
      <div className={"container mx-auto text-center py-6"}>
        {status === "won" ? (
          <div>
            <h2
              className={
                "text-3xl lg:text-8xl font-bold mb-2 lg:mb-8 animated lightSpeedIn"
              }
            >
              You Won!
            </h2>
            <p className={`mb-4 text-xs lg:text-base`}>The word was</p>
            <p className={`mb-4 font-bold text-2xl lg:text-4xl`}>
              {activeWord.map((letter, i) => {
                return <span key={i}>{letter.letter}</span>;
              })}
            </p>
            {/* <p className={`text-xs lg:text-base px-4`}>
              It took you {timer} second{timer > 1 ? "s" : ""} to figure
              this one out with a score of {finalScore}
            </p> */}
            {profile && <DisplayEndGame score={finalScore} oldScore={oldScore} />}
          </div>
        ) : (
          <div>
            <h2
              className={
                "text-4xl lg:text-8xl font-bold mb-2 lg:mb-8 animated lightSpeedIn"
              }
            >
              Oops
            </h2>
            <p className={`mb-4`}>The word was</p>
            <p className={`mb-4 texzt-2xl lg:text-4xl font-bold `}>
              {activeWord.map((letter, i) => {
                return <span key={i}>{letter.letter}</span>;
              })}
            </p>
            {/* <p className={`text-xs lg:text-base px-4`}>
              It took you {timer} second{timer > 1 ? "s" : ""} to fail this
              one. Your score was {finalScore}
            </p> */}
            {profile && <DisplayEndGame score={finalScore} oldScore={oldScore} />}
            
          </div>
        )}
        <div className={"w-full text-center"}>
          {word.categorys && (
            <div className="flex justify-center my-3 w-full">
              <div className="bg-white shadow-xl rounded-lg w-full lg:w-1/2">
                <ul className="divide-y divide-gray-300">
                  <li className="p-4 hover:bg-gray-50 cursor-pointer">
                    <button onClick={() => pullWord(activeCat)}>
                      Try same category
                    </button>
                  </li>
                  {word.categorys.map((cat, i) => {
                    return (
                      <li
                        key={`${cat.slug}cat`}
                        className="p-4 hover:bg-gray-50 cursor-pointer"
                      >
                        <button onClick={() => pullWord(cat.slug)}>
                          {cat.name}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={"mt-2"}>
          <Link
            to={`/`}
            className={
              "bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            }
          >
            Return Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EndGameScreen;
