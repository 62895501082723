import React from "react";
// import { AnswerLetter } from "./styled/letters";

function Answer({ word }) {
  let cnt = 0;
  // let wordCnt = 0;
  let words = [[]];
  // let thisOne = [[]];
  word.forEach((element) => {
    if (element.letter === " ") {
      cnt++;
      // wordCnt = 0;
      words.push([]);
    } else {
      words[cnt].push(element);
    }
  });
  // console.log("words", words);

  const classNameGap =
    word.length > 14
      ? "gap-1 md:gap-4 xl:gap-4"
      : word.length > 10
      ? "gap-1 lg:gap-4"
      : "gap-1 lg:gap-10";
  const classNameWrapper =
    word.length > 14
      ? "w-4 xs:w-4 md:w-6 lg:w-8 xl:w-16 h-10 md:h-12 lg:h-20 xl:h-28"
      : word.length > 10
      ? "w-4 sm:w-8 lg:w-12 xl:w-18 h-10 h-10 sm:h-12 md:h-16 lg:h-20 xl:h-24"
      : "w-5 sm:w-8 lg:w-20 xl:w-24 h-8 h-10 sm:h-12 md:h-16 lg:h-24 xl:h-26";

  const classNameText =
    word.length > 14
      ? "text-md md:text-4xl lg:text-5xl xl:text-7xl"
      : word.length > 10
      ? "text-xl md:text-4xl lg:text-4xl xl:text-6xl"
      : "text-2xl md:text-4xl lg:text-6xl xl:text-6xl";

  return (
    <div
      className={`Answer w-full flex flex-wrap justify-center content-around ${classNameGap}`}
    >
      {words.map((section, j) => {
        return (
          <div key={`${j}-letter`}>
            <div className={"flex flex-nowrap mx-4 md:mx-6"}>
              {section.map((letter, i) => {
                let border =
                  letter.letter === " " ||
                  letter.letter === "'" ||
                  letter.letter === ":" ||
                  letter.letter === "-" ||
                  letter.letter === "." ||
                  letter.letter === "!"
                    ? "border-white "
                    : "border-black";
                return (
                  <div
                    className={`border-b-8 mx-1 md:mx-2 ${border} ${classNameWrapper} `}
                    key={`${i}-letter`}
                  >
                    {letter.found === true ? (
                      <p
                        className={`animated bounceIn w-full lg:pt-4 lg:pb-4 text-center mb-0 ${classNameText} `}
                      >
                        {letter.letter === " " ||
                        letter.letter === "-" ||
                        letter.letter === "'" ||
                        letter.letter === ":" ||
                        letter.letter === "." ||
                        letter.letter === "!" ? (
                          letter.letter === " " ? (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp " }}
                            />
                          ) : (
                            <span className={"text-4xl"}>{letter.letter}</span>
                          )
                        ) : (
                          letter.letter
                        )}
                      </p>
                    ) : (
                      <p> </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Answer;
