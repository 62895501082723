import React, { useEffect } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

const Horay = () => {
  const { width, height } = useWindowSize();

  // const [opacity, setOpacity] = useState(1);

  useEffect(() => {}, []);
  return <Confetti initialVelocityX={10} width={width} height={height} />;
};

export default Horay