import React from "react";
import { graphql } from "gatsby";
import { AuthProvider } from "../context/auth-context";
import WordCategoryWrapper from "../components/word-category-wrapper"; 

const WordCategory = ({ data }) => {
  return (
    <AuthProvider>
      <WordCategoryWrapper data={data} />
    </AuthProvider>
  );
};

export default WordCategory;

export const pageQuery = graphql`
  query ParentCategorys($id: String!, $slug: String!) {
    category(slug: { eq: $slug }) {
      slug
      name
      original_id
      _id
      id
      description
      image {
        childImageSharp {
          gatsbyImageData(
            width: 80
            height: 80
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        ext
        extension
        url
        publicURL
      }
      ancestors {
        _id
        name
        slug
      }
    }
    children: allCategory(
      sort: { fields: name }
      filter: { ancestors: { elemMatch: { _id: { eq: $id } } } }
    ) {
      categorys: edges {
        category: node {
          name
          original_id
          slug
          _id
          id
          description
          imageUrl
          image {
            childImageSharp {
              gatsbyImageData(
                width: 80
                height: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            ext
            extension
            url
            publicURL
          }
          ancestors {
            _id
            name
            slug
          }
        }
      }
    }
  }
`;
