import React from "react";
// import {   navigate } from "gatsby";
 
import Game from "./game/Game";

const GameTemplate = ({word, pullWord, gameDetails}) => {
  // const { word } = data;

  const endGame = async () => {
    // navigate(`/${word.categories.category[0].slug || ``}`);
    // alert("finished!")
    // pullWord()
  };
  // console.log("word data", word);
  return ( 
      <div>
        <div className={"w-full"}> 
          <Game word={word} pullWord={pullWord} gameDetails={gameDetails} endGame={endGame} />
        </div>
      </div> 
  );
};

export default GameTemplate;
