import React from "react";

// import { LetterButton } from "./styled/letters";

function Alphabet({ alphabet, runClick }) {
  return (
    <div
      className="Alphabet flex "
      style={{
        display: "flex flex-wrap gap-10",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {alphabet.map((letter, i) => {
        const selected =
          letter.letter !== " "
            ? letter.clicked === true
              ? "border-red-400 text-red-400 cursor-not-allowed opacity-50"
              : "border-black cursor-pointer  text-black hover:bg-blue-500 hover:text-white"
            : "border-white";
        return (
          <button
            key={i}
            className={`animated  flipInX  answer-letter h-10 w-10 mb-2 mx-1 text-xl md:h-12 md:w-12 md:mb-4 md:mx-2 md:text-2xl  lg:h-20 lg:mb-4 lg:mx-2 lg:w-20 lg:text-6xl xl:h-24 xl:mb-6 xl:mx-3 xl:w-24 xl:text-7xl text-center  border-4 ${selected} `}
            onClick={(e) => {
              if (letter.clicked === false) {
                runClick(letter.letter);
              } else {
              }
            }}
          >
            {letter.letter}
          </button>
        );
      })}
    </div>
  );
}

export default Alphabet;
