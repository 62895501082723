import React, { useState, useEffect, useRef } from "react";
import Alphabet from "./Alphabet";
import EndGameScreen from "./EndGame";
import { Link } from "gatsby";
import { useToasts } from "react-toast-notifications";
// import { AuthContext } from "../../context/auth-context";
import HeaderAd from "../ads/header-ad";
// import ReactTimerStopwatch from "react-stopwatch-timer";
// import Typography from "@material-ui/core/Typography";
import Answer from "./Answer";
import useSound from "use-sound";
import cheer from "../../sounds/cheer.mp3";
import incorrect from "../../sounds/pop-up-on.mp3";
import correct from "../../sounds/pop.mp3";

// const api_endpoint = process.env.GATSBY_WORD_API;
// const word = "TESTING";
// const wordArray = word.split("").map((x) => {
//   return { found: false, letter: x };
// });

const toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = alpha.map((x) => {
  return { clicked: false, letter: String.fromCharCode(x) };
});
function Game({ endGame, word = null, pullWord, gameDetails }) {
  // const { activeCat, user } = React.useContext(AuthContext);
  const [timer, setTimer] = useState(0);
  const [seconds, setSeconds] = useState(0);
  // const [isActive, setIsActive] = useState(false);
  // const [isPaused, setIsPaused] = useState(false);
  const countRef = useRef(null);
  // const [timerOn, setTimerOn] = useState(true);
  const [activeWord, setActiveWord] = useState([]);
  const [pause, setPause] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(7);
  const [points, setPoints] = useState(0);
  const [wrong, setWrong] = useState(0);
  const [right, setRight] = useState(0);
  const [status, setStatus] = useState("started");
  const [activeButtons, setActiveButtons] = useState(alphabet);
  // const [selectedLetters, setSelectedLetters] = useState([]);
  const [playWrong] = useSound(incorrect);
  const [playCorrect] = useSound(correct);
  const [playCheer] = useSound(cheer);
  const { addToast } = useToasts();
  const [gameover, setGameOver] = useState(false);

  const handleStart = () => {
    // setIsPaused(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(countRef.current);
    setSeconds(timer);
    // setIsPaused(false);
  };
  useEffect(() => {
    var wordArray = word.word
      .toUpperCase()
      .split("")
      .map((x) => {
        return {
          found:
            x === " " ||
            x === "'" ||
            x === ":" ||
            x === "." ||
            x === "!" ||
            x === "-"
              ? true
              : false,
          letter: x,
        };
      });
    setActiveWord(wordArray);
    handleStart();
  }, []);

  useEffect(() => {
    tallyGame();
  }, [activeWord]);

  const runClick = async (value) => {
    var tmpRight = right;
    var tmpPoints = points;
    if (pause !== true) {
      var tmp = activeButtons.map((x, i) =>
        x.letter === value ? { ...x, clicked: true } : x
      );
      await setActiveButtons(tmp);
      // var selected = await activeButtons.filter((x) => {
      //   return x.clicked === true;
      // });
      // await setSelectedLetters(selected);
      var tmpWord = await activeWord.map((x, i) =>
        x.letter === value ? { ...x, found: true } : x
      );
      var previousActiveWord = [...activeWord];
      await setActiveWord(tmpWord);
      // console.log(selected, selectedLetters);
      // console.log(score);
      if (JSON.stringify(tmpWord) === JSON.stringify(previousActiveWord)) {
        await setRight(0);
        await setWrong(wrong + 1);

        if (score - 1 === 0) {
          handlePause();
          await setPoints(points - 5);
          await setStatus("failed");
          setGameOver(true);
          // endGame();
        } else {
          await setPoints(points - 5);
          playWrong();
          await setScore(score - 1);
          addToast(`-5`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 1600,
            placement: "bottom-right",
          });
        }
      } else {
        tmpPoints = tmpPoints + 5;
        tmpRight++;
        await setPoints(tmpPoints);
        await setRight(tmpRight);
        await setWrong(0);
        await playCorrect();
        addToast(`+5`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1400,
          placement: "bottom-right",
        });

        // console.log("Tallying points", tmpRight);
        if (tmpRight >= 3) {
          setPoints(tmpPoints + 15);
          addToast(`+15 BONUS!`, {
            appearance: "warning",
            autoDismiss: true,
            autoDismissTimeout: 1600,
            placement: "bottom-right",
          });
        }
      }
    }
  };

  const tallyGame = async () => {
    if (activeWord.length > 0) {
      var results = [...activeWord];
      var leftOver = results.filter((x) => {
        return x.found === false;
      });
      // console.log("Left Over", leftOver);
      if (leftOver.length === 0) {
        // console.log("Tally Game");
        handlePause();
        setPause(true);
        // setTimerOn(false);
        playCheer();
        setStatus("won");
        setTimeout(function() {
          setGameOver(true);
        }, 1500);
      }
    }
  };

  // const resetGame = async (databaseId) => {
  //   setLoading(true);
  //   alert("resetting");
  //   // var url = `${api_endpoint}category/${databaseId}?notin=${word.databaseId}`;

  //   // fetch(url)
  //   //   .then((response) => {
  //   //     return response.json();
  //   //   })
  //   //   .then((data) => {
  //   //     if (data.status === "success") {
  //   //       console.log("Try another path", data.id);
  //   //       const newPath = `/words/${data.id}`;
  //   //       setLoading(false);
  //   //       navigate(newPath);
  //   //     } else {
  //   //       navigate(`/`);
  //   //     }
  //   //   });
  // };

  return (
    <div className="fixed z-50 bg-white top-0 bottom-0 right-0 left-0 overflow-y-auto ">
      <div className={"container mx-auto mt-10 px-2 lg:px-4"}>
        <div className={"mx-auto mb-8 lg:mb-10"}>
          <HeaderAd />
        </div>
        <div
          className={
            "grid grid-cols-2 gap-2 lg:gap-10 text-xl lg:text-2xl bg-primary  pt-2  "
          }
        >
          <div className={"w-full text-xs lg:text-base whitespace-nowrap	"}>
            Time: {toHHMMSS(timer)}
          </div>
          <div className={"w-full text-right text-xs lg:text-base"}>
            Score: {points}
          </div>
        </div>

        {gameover === true ? (
          <EndGameScreen
            pullWord={pullWord}
            // resetGame={resetGame}
            endGame={endGame}
            seconds={seconds}
            status={status}
            activeWord={activeWord}
            word={word}
            finalScore={points}
            timer={timer}
            gameDetails={gameDetails}
            gameover={gameover}
          />
        ) : null}
        <div className={"mb-10 px-1 lg:px-10"}>
          {activeWord && activeWord.length > 0 ? (
            <Answer word={activeWord} />
          ) : null}
        </div>
        <div className={"w-full grid-cols-1  grid"}>
          <div className={"col-span-1 "}>
            <Alphabet alphabet={activeButtons} runClick={runClick} />
          </div>
          <div
            className={
              "col-span-1 lg:col-span-1 text-xs text-primary text-center"
            }
          >
            <p>Mistakes Left: {score}</p>
          </div>
        </div>
        <div className={"w-full text-center  pb-4  mt-10"}>
          {pause === true ? null : (
            <Link
              to={`/`}
              className={
                "bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              }
            >
              End Game
            </Link>
          )}
        </div>

        <div className={"mx-auto mb-8 lg:mb-10"}>
          <HeaderAd />
        </div>
      </div>
    </div>
  );
}

export default Game;
